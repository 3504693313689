import React from 'react';

import BlueprintLayout from '../components/ui/Layout/BlueprintLayout';

import { BLUEPRINT_FR_MENU } from '../utils/constants';

import BlueprintFrTemplate from '../templates/BlueprintFrTemplate';

const BlueprintFr = () => {
  return (
    <BlueprintLayout menuItems={BLUEPRINT_FR_MENU}>
      <BlueprintFrTemplate />
    </BlueprintLayout>
  );
};

export default BlueprintFr;
