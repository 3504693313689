import React from 'react';
import classnames from 'classnames';

import DBProgramsByRegion from '../images/charts/chart_database-programs-by-region_french.png';
import DBProgramsByType from '../images/charts/chart_database-programs-by-type_french.png';
import INNPartnerByRegion from '../images/charts/chart_deep-dive-programs-by-region_french.png';
import INNPartnerByType from '../images/charts/chart_deep-dive-programs-by-type_french.png';

const BlueprintFrTemplate = () => {
  return (
    <>
      <div style={{ position: 'relative' }}>
        <article className={'module-featured'}>
          <h1>La Plate-forme mondiale du Réseau des nations incarcérées’S:</h1>
          <p>Remarques et Reflexions</p>
          <figure>
            <img
              src="https://www.berlin.de/converjon/?ts=1418132797&width=540&height=270&url=https%3A%2F%2Fwww.berlin.de%2Fjustizvollzug%2F_assets%2Fjva-heidering%2Fdie-anstalt%2Fpforte.jpg"
              alt="Placeholder"
              width="1440"
              height="710"
            />
          </figure>
        </article>
        <article className={'s16'} id={'context'}>
          <header className={'text-center'}>
            <h2 style={{ color: 'black' }}>
              L’incarcération de masse est une calamité mondiale.
            </h2>
          </header>
          <div className={'triple'}>
            <div>
              <p>
                Quelque 11 millions de personnes dans le monde sont actuellement
                derrière les barreaux. Ce nombre augmente régulièrement. Entre
                2008 et 2011, la population carcérale a augmenté dans 78% des
                pays et entre 2000 et 2016, elle a augmenté de près de 20%.
                Entre 2000 et 2015, la population carcérale totale en Océanie a
                augmenté de près de 60%; dans les Amériques, elle a globalement
                augmenté de plus de 40% (14% aux États-Unis; plus de 80% dans
                les pays d'Amérique centrale et 145% dans les pays d'Amérique du
                Sud). Le nombre de condamnés à mort dans le monde a presque
                doublé depuis 2000.
              </p>
            </div>
            <div>
              <p>
                Plus de 3,2 des 11 millions de personnes emprisonnées n'ont été
                condamnées, ce sont des personnes juridiquement innocentes en
                attente d'un procès. Sur 11 millions, un sur cinq est incarcéré
                pour des infractions liées à la drogue, 83% pour simple
                possession de drogue. Un rapport pour le{' '}
                <a
                  style={{ color: 'black' }}
                  href="https://justice.sdg16.plus/report"
                >
                  roupe de travail sur la justice
                </a>{' '}
                a calculé que 4,4 milliards de personnes sont exclues de la
                possibilité offerte par la loi et que 244 millions de personnes
                vivent dans des conditions extrêmes d'injustice
              </p>
            </div>

            <div>
              <p>
                Bien que connu sous le nom d'incarcération de masse, il n'a pas
                un impact égal sur les masses. Les communautés « autres »,
                qu'elles soient afro-américaines ou latino-américaines aux
                États- Unis ; Peuples autochtones au Canada, en Australie et en
                Nouvelle-Zélande; Les Roms dans toute l'Europe; les pauvres des
                quatre coins du Sud - sont ciblés et puis arrêtés par les
                systèmes de justice pénale, et sont donc entraînés à des niveaux
                disproportionnés, en raison d'une matrice de réalités
                historiques et actuelles ancrées dans les normes capitalistes.
              </p>
            </div>
            <div>
              <figure>
                <img
                  style={{ minHeight: '625px', objectFit: 'cover' }}
                  className={'desktop-only'}
                  src="https://static1.squarespace.com/static/57cd909c197aea06e48be99b/t/5ba24f944d7a9c88ca636974/1537363932300/app_at_work.JPG"
                  alt="Placeholder"
                  width="400"
                  height="625"
                />
                <img
                  className={'desktop-hide'}
                  src="https://static1.squarespace.com/static/57cd909c197aea06e48be99b/t/5ba24f944d7a9c88ca636974/1537363932300/app_at_work.JPG"
                  alt="Placeholder"
                  width="360"
                  height="295"
                />
              </figure>
            </div>
            <div>
              <p>
                Ce n'est pas seulement une crise des droits de l'homme. C'est
                également une menace mondiale grave pour la paix et la sécurité
                des communautés, car les prisons - une méthode démodée et non
                innovante de sécurité publique et de réduction de la criminalité
                - ne construisent pas de communautés plus sûres ni ne font
                progresser la paix et la justice. De nombreuses données le
                appuient cette idée.{' '}
                <a
                  style={{ color: 'black' }}
                  href="https://penphilanthropy.org/files/Focus_Areas/Criminal_Justice_Reform/The_impacts_of_incarceration_on_crime_10.pdf"
                >
                  Rapport de projet Open Philanthropy 2017
                </a>{' '}
                a examiné 35 études internationales et conclu que des peines
                plus sévères "ne dissuadent guère le crime" et que le temps
                passé en prison "tend à accroître la criminalité [d'une
                personne] après sa libération" - autrement dit, les prisons
                produisent le crime au lieu de l'arrêter. Une étude menée en{' '}
                <a
                  href="https://Nature.com/articles/s41562-019-0604-8.epdf"
                  style={{ color: 'black' }}
                >
                  {' '}
                  2019 par l'Université de Californie à Berkeley
                </a>
                , a révélé que les peines de prison ne dissuadaient pas les
                personnes de se livrer à des crimes violents et que les garder
                derrière des barreaux n'avait pratiquement aucun effet sur la
                réduction des crimes violents en général
              </p>
              <p>
                Le Réseau des nations incarcérées est né de cela: un réseau
                mondial et un groupe de réflexion qui soutient, incite et
                vulgarise les efforts novateurs en matière de prison et de
                justice, en repensant les efforts à travers le monde.{' '}
              </p>
            </div>
            <div>
              <figure className={'m35'}>
                <img
                  src="https://static.wixstatic.com/media/b8d0a9_816c8efd1eec47eab0fed67369ea700f~mv2_d_4608_3456_s_4_2.jpg/v1/fill/w_899,h_300,al_c,q_80,usm_0.66_1.00_0.01/b8d0a9_816c8efd1eec47eab0fed67369ea700f~mv2_d_4608_3456_s_4_2.webp"
                  alt="Placeholder"
                  width="400"
                  height="175"
                />
              </figure>
              <p>
                Cette plate-forme multimédia RNI - à notre connaissance, la
                première en son genre - est également née de cette crise
                mondiale et est animée par une passion pour les solutions,
                qu'elles soient grandes ou petites. Présentant des efforts
                novateurs en matière d’imagination et de réforme des prisons à
                travers le monde, il se compose d’une vaste base de données
                regroupant près de 800 programmes mondiaux et d’une exploration
                en profondeur de 82 programmes particulièrement prometteurs
                comportant un éventail d’activités, que nous avons regroupés en
                catégories.
              </p>
            </div>
          </div>
        </article>
        <article className={'module-wide'} id={'premises'}>
          <header className={'text-center'}>
            <h2 style={{ color: 'black' }}>
              LES LOCAUX DE LA PLATE-FORME DU RNI
            </h2>
            <p style={{ color: '#7D7D7D' }}>
              La plateforme du RNI adopte trois principes a priori concernant la
              justice
            </p>
          </header>
          <div className={classnames('double', 'a')}>
            <div className={'m0'}>
              <ul>
                <li>
                  <p>
                    Les modèles de justice non pénitentiaires fondés sur la
                    justice réparatrice et d'autres approches de diversion
                    constituent des moyens plus humains et plus efficaces de
                    bâtir des communautés sûres et équitables par rapport aux
                    prisons et à la détention. Celles-ci ne devraient pas être
                    «des alternatives à l'incarcération» mais plutôt le
                    principal principe de la justice, pour ainsi dire. Et cela
                    devrait reformuler nos questions fondamentales sur la
                    justice. Plutôt que de demander simplement «quels programmes
                    de réhabilitation des prisons sont efficaces?», Nous devons
                    poser des questions qui nous bougent au-delà des prisons:
                    «Qu'est-ce qui crée des communautés sûres? Quels sont les
                    besoins de ceux qui ont été lésés et comment pouvons-nous
                    les aborder?
                  </p>
                </li>
                <li>
                  <p>
                    Bien qu'un changement systémique radical et une vision
                    globale innovante soient indispensables pour faire avancer
                    l'aiguille mondiale vers la justice, le travail de réforme
                    et les efforts progressifs restent à faire, car des millions
                    de vies sont en jeu dans l'immédiat. La réduction de la
                    population carcérale et l'amélioration des conditions de
                    détention sont essentielles au nom des droits de l'homme et
                    de la sécurité de la communauté. Il est donc possible, même
                    si cela est paradoxal, de s’engager dans un travail de
                    réforme qui sauve des vies tout en œuvrant à la mise en
                    place de divers modes d’abolition des prisons qui permettent
                    d’éviter de telles réformes.
                  </p>
                </li>
              </ul>
            </div>
            <div>
              <ul>
                <li>
                  <p>
                    Quand il s'agit de communautés sécuritaires, rien ne
                    l'emporte sur la prévention; cela se traduit par une société
                    fondée sur l'égalité, l'équité, des opportunités économiques
                    et des services sociaux robustes. Comme le sait le mouvement
                    Justice Reinvestment, le véritable travail de justice ne
                    peut être accompli en vase clos. il est holistique et large,
                    transformant toutes les facettes d’une société au nom de la
                    paix.
                  </p>
                </li>
              </ul>
              <figure>
                <img
                  src="https://www.score.gov.sg/images/default-source/default-album/chef-working-in-kitchen.jpg"
                  alt="Placeholder"
                  width="785"
                  height="280"
                />
              </figure>
            </div>
          </div>
        </article>
        <article className={'module-wide'} id={'goals'}>
          <figure className={'background'}>
            <img
              src="https://mediaprocessor.websimages.com/www.corkalliancecentre.com/patricks%20hill%20view.jpg"
              alt="Placeholder"
              width="1440"
              height="800"
            />
          </figure>
          <header className={'text-center'}>
            <h2>GOBJECTIFS DE LA PLATEFORME RNI</h2>
            <p>RNI a plusieurs objectifs pour la plate-forme multimédia</p>
          </header>
          <div className={'triple'}>
            <div>
              <ul>
                <li>
                  Créer un plan directeur et une feuille de route pour des
                  solutions prometteuses à la crise mondiale des prisons.
                </li>
                <li>
                  Démontrer que les discussions sur la réforme et la réinvention
                  des prisons peuvent avoir lieu à l'échelle mondiale et à
                  travers les frontières, tout en reconnaissant les limites des
                  généralisations …d’un pays à l'autre.
                </li>
                <li>
                  Construire une coalition transfrontalière et renforcer les
                  organisations qui effectuent un travail vital en matière de
                  justice, en particulier celles qui le font et desservent les
                  populations carcérales dans des environnements publics
                  hostiles et punitifs.
                </li>
              </ul>
            </div>
            <div>
              <ul>
                <li>
                  Construire une vaste base de données de sorte que les
                  organisations mondiales engagées dans la réforme pénitentiaire
                  et les universitaires / chercheurs / innovateurs du monde
                  entier puissent être littéralement sur la même longueur
                  d’ondes.
                </li>
                <li>
                  Générer un réseau mondial de décideurs qui permette la
                  réplicabilité de programmes performants dans le monde entier.
                </li>
                <li>
                  Provoquez des conversations transfrontalières sur des luttes
                  et des succès communs.
                </li>
              </ul>
            </div>
            <div>
              <ul>
                <li>
                  Combinez les données avec l’imagination pour attirer de
                  nouveaux yeux et de nouvelles oreilles face à la crise
                  mondiale de l'incarcération de masse, notamment des acteurs
                  essentiels dans ce travail: les nouveaux bailleurs de fonds,
                  le grand public et les médias.
                </li>
                <li>
                  Fournir une ressource pratique pour les organisations des pays
                  avec des prisonniers nés à l'étranger qui peuvent bénéficier
                  de liens avec des organisations étrangères.
                </li>
              </ul>
            </div>
          </div>
        </article>
        <article className={'module-wide'} id={'audience'}>
          <header className={'text-center'}>
            <h2 style={{ color: 'black' }}>AUDIENCE POUR LA PLATEFORME RNI</h2>
            <p style={{ color: '#7D7D7D' }}>
              L'audience du RNI pour la plateforme mondiale est vaste
            </p>
          </header>
          <div className={'module-aside'}>
            <figure>
              <img
                style={{ maxHeight: '330px', objectFit: 'cover' }}
                src="https://www.crcnsw.org.au/wp-content/uploads/2016/10/highlight2.jpg"
                alt="Placeholder"
                width="865"
                height="330"
              />
            </figure>
            <div>
              <p>
                Les agences gouvernementales et ministères; les ONG travaillant
                dans le domaine de la justice; les médias, les universitaires et
                les étudiants dans les domaines liés à la justice; les bailleurs
                de fonds potentiels du secteur des entreprises; les
                philanthropies et autres bailleurs de fonds; militants et
                citoyens concernés; les personnes incarcérées et / ou
                anciennement incarcérées «vivant dans le placard», où elles
                combattent la stigmatisation et l'exclusion sociale; les
                familles et les proches de la communauté incarcérée.
              </p>
            </div>
          </div>
        </article>
        <article className={'mn'} id={'methodology'}>
          <header className={'text-center'}>
            <h2 style={{ color: 'black' }}>
              METHODOLOGIE DE COLLECTE DE DONNEES / ORGANISATION DE LA
              PLATEFORME DU RNI
            </h2>
          </header>
          <div className={'double a module-spaces tablet-compact'}>
            <div>
              <p>
                Pour la base de données, qui contient près de 800 programmes
                dans le monde entier, le RNI a consulté son équipe de
                consultants en justice mondiale, ainsi que des conseillers
                pédagogiques et des membres de l’équipe de diverses fondations.
                INN a également entrepris des recherches systématiques sur le
                Web à l'aide de mots clés associés à des noms de pays («prison»,
                «réforme pénitentiaire», «justice réparatrice»,
                «déjudiciarisation», «Alternative à l'incarcération»
                (déjudiciarisation du tribunal / du délinquant)). Sont inclus
                tous les programmes avec un site Web opérationnel et des
                informations de contact. À la connaissance du RNI, la base de
                données est la seule cartographie globale de ce type et de cette
                étendue, mais elle ne prétend pas être absolue. La recherche sur
                le Web a été effectuée principalement en anglais et en espagnol.
              </p>
              <p>
                En sélectionnant les programmes à inclure dans la section de la
                plate-forme INN Partner, RNI a consulté ses consultants en
                justice mondiale et a demandé des recommandations concernant des
                programmes particulièrement puissants; des contacts sur le
                terrain issus de milieux universitaires et d'ONG ont également
                été consultés. La priorité a été donnée aux programmes dans
                lesquels les personnes directement touchées jouent un rôle
                central, car RNI estime, selon JustLeadership USA, que «les
                personnes les plus proches du problème sont les plus proches de
                la solution».
              </p>
              <p>
                En classant les programmes dans la base de données, la RNI a
                utilisé des informations accessibles au public. Les
                organisations de la plate-forme INN Partner ont classé leurs
                programmes eux-mêmes. Souvent, une organisation / institution
                gère plusieurs types de programmes, chacun étant répertorié
                comme un programme individuel séparé. De nombreuses
                organisations associent services directs et défense des
                intérêts, mais ne les voient souvent pas comme des «programmes»
                distincts. nous avons classé ce travail synergique dans des
                types de programme distincts.
              </p>
            </div>
            <div>
              <p>
                Les organisations participant à la plate-forme INN Partner ont
                reçu un questionnaire de 50 questions (
                <span className="strong">
                  <a
                    style={{ color: 'black' }}
                    href="https://assets.ctfassets.net/fhwlk5jsnns3/16pO6mhH06ep3rvEKWtAF4/a498468563c0348dae7092be6a599af5/INN_DirectServices_Questionaire_.pdf"
                  >
                    ici
                  </a>
                  ,{' '}
                  <a
                    style={{ color: 'black' }}
                    href="https://assets.ctfassets.net/fhwlk5jsnns3/2bs7ebzwYpqUTW0e38aVz4/8b82c979473cd3023d7dc7edfe07a02b/INN_Alternatives_to_Incarceration_Questionaire_.pdf"
                  >
                    ici
                  </a>
                  ,{' '}
                  <a
                    style={{ color: 'black' }}
                    href="https://assets.ctfassets.net/fhwlk5jsnns3/16pO6mhH06ep3rvEKWtAF4/a498468563c0348dae7092be6a599af5/INN_DirectServices_Questionaire_.pdf"
                  >
                    ici
                  </a>{' '}
                  &{' '}
                  <a
                    style={{ color: 'black' }}
                    href="https://assets.ctfassets.net/fhwlk5jsnns3/5MOyuKg9Phsrh1yLhcub56/def96255a13b7ba334ff22bfa22bf77f/INN_DirectServices_Innovative_Alternatives_to_Traditional_Prisons_Questionaire_.pdf"
                  >
                    ici
                  </a>
                </span>
                ) sur leur travail, leurs méthodes, leurs théories du
                changement, etc. ces questionnaires comprenaient des choix de
                réponses ouvertes et fermées dans le but de collecter des
                données quantitatives et qualitatives sur chaque programme. 39
                organisations ont également reçu des{' '}
                <span className="strong">
                  <a
                    style={{ color: 'black' }}
                    href="https://assets.ctfassets.net/fhwlk5jsnns3/1HPZ9vmVLMG2nZrwsZu6X4/87c80904023e859a826f35a97e07cbeb/INN_GlobalAssessment_ClientSurvey.docx"
                  >
                    sondages de clients
                  </a>
                </span>{' '}
                à distribuer à cinq participants au programme; Ceux-ci devaient
                être utilisées pour rassembler des données qualitatives sur les
                programmes.
              </p>
              <figure className={'mn'}>
                <img
                  src="https://www.genepi.fr/wp-content/uploads/2018/03/Re%C2%A6%C3%BCflexion-et-formation.jpg"
                  alt="Placeholder"
                  width="620"
                  height="555"
                />
              </figure>
              <p>
                Le Dr Baz Dreisinger, directeur exécutif de la RNI, a visité 52
                programmes de la plate-forme INN Partner, dont au moins un de
                chaque région géographique. Des entretiens ont eu lieu avec des
                personnes clés représentant l'organisation et avec des clients.
              </p>
              <p>
                Plusieurs programmes ont été invités à participer à la
                plate-forme INN Partner, mais ils ont été incapables de le faire
                pour diverses raisons. Parmi ceux-ci, le RNIa estimé que
                certains étaient trop essentiels pour être omis. Des pages ont
                donc été créées pour leur permettre de présenter leur mission,
                leur approche et leur couverture médiatique, tout en excluant
                les données plus approfondies, qui ne pouvaient pas être
                collectées à ce moment.
              </p>
            </div>
          </div>
        </article>
        <article className={'module-wide'}>
          <div className={classnames('double', 'a', 'tablet-compact')}>
            <div>
              <p className={classnames('strong', 'm40')}>
                Lors de la sélection des programmes pour la plate-forme INN
                Partner, un effort a également été fait pour équilibrer
                plusieurs facteurs afin de permettre:
              </p>
              <hr />
              <ul>
                <li>
                  Inclusion de programmes provenant d'un large éventail de
                  régions géographiques
                </li>
                <li>
                  Un éventail de programmes nationaux de grande portée et locaux
                  à plus petite échelle.
                </li>
                <li>
                  Une combinaison pertinente de programmes globaux, axés sur les
                  systèmes, ainsi que des programmes axés sur les services
                  directs, axés sur l'individu, qui «nettoient les dégâts» et
                  sauver des vies ici et maintenant.
                </li>
                <li>
                  Inclusion des programmes établis de longue date et des
                  organisations dotées de programmes plus récents et plus petits
                  qui n'ont peut-être pas encore obtenu des résultats testés et
                  prouvés, mais dont le modèle est prometteur et innovant et qui
                  bénéficieraient ainsi d'une présence sur une plate-forme
                  mondiale.
                </li>
              </ul>
              <hr />
              <p>
                <span className="strong">REMARQUE:</span> La prévention ne
                figure pas dans cette liste - y compris les opportunités de
                services sociaux, les interventions communautaires (en
                particulier celles destinées aux jeunes) et le développement
                économique - mais cela dépasse le cadre de la plate-forme du RNI
                pour le moment.{' '}
              </p>
              <p>
                INN a également noté que les travaux étaient axés sur les
                catégories pertinentes directement touchées:
              </p>
              <ul>
                <li>
                  Les groupes surreprésentés dans le système de justice pénale
                </li>
                <li>Les femmes dans le système de justice</li>
                <li>Populations à la demande / pré-procès</li>
                <li>Juveniles / Jeunesse</li>
                <li>Familles des incarcérés</li>
                <li>Populations carcérales du LGBTQ</li>
              </ul>
            </div>
            <div>
              <p className={classnames('strong', 'm40')}>
                Identifier les catégories d'organisation de la plate-forme était
                la première étape pour définir un terrain d'entente entre les
                programmes et créer une sorte de schéma directeur ou de feuille
                de route pour les solutions. Ces catégories sont:
              </p>
              <hr />
              <ul>
                <li>Alternatives à l'incarcération</li>
                <li>Politique / travail de plaidoyer</li>
                <li>
                  Services directs pour les personnes touchées par le système
                  pénitentiaire (personnes incarcérées / anciennement
                  incarcérées et leurs familles)
                </li>
              </ul>
              <hr />
              <p>
                <span className="strong">REMARQUE: </span>Définir le «succès» en
                matière de réforme du système pénitentiaire reste une question
                complexe et nuancée. La récidive est couramment utilisée comme
                mesure, mais comme le notent en permanence les chercheurs et les
                praticiens, ces chiffres sont souvent trompeurs et simplistes.
                (Le Brésil et El Salvador, par exemple, enregistrent des taux de
                récidive globalement moins élevés que le Chili, mais cela ne
                témoigne pas nécessairement du succès des systèmes
                pénitentiaires de ces derniers pays. Cela peut simplement être
                attribué aux peines courtes prononcées au Chili et qui font que
                les gens entrent et sortent du système à des taux plus élevés).
                Compte tenu de cette réalité, le RNI a explicitement demandé aux
                organisations leurs idées sur le succès ("Qu'est-ce qui rendrait
                votre travail inutile?" "Quelle est la vision de votre programme
                pour un changement transformateur à long terme?") et dans
                l’ensemble a cherché à rassembler des informations quantitatives
                et données qualitatives sur les programmes dans la présentation
                de leurs travaux. Vous trouverez plus d'informations à ce sujet
                et sur la pratique factuelle dans son ensemble dans la section
                réflexion “Grandes images"
              </p>
              <p>
                <span className="strong">REMARQUE: </span>Au nom de
                l'accessibilité - principe directeur de tout le travail des RNI
                -, cette plate-forme utilise la terminologie courante que le RNI
                juge problématique. Comme mentionné ci-dessus, «alternative à
                l'incarcération» place à tort la prison au cœur d'un système de
                justice. Des termes tels que «réhabilitation» et «insertion» /
                «réinsertion» impliquent que les personnes en prison ont été
                habilitées / insérées dans la société en premier lieu, alors que
                nous savons en réalité que le contraire est vrai: globalement,
                les personnes en prison proviennent de la plupart des
                communautés marginalisées et défavorisées et, par conséquent,
                toute possibilité qui leur est offerte en prison est
                probablement une première chance, et non une seconde.{' '}
              </p>
              <p>
                <span className="strong">REMARQUE: </span>La section INN Partner
                de cette plate-forme ne constitue pas une évaluation des
                programmes utilisant un outil de mesure universel. les
                programmes ont été sélectionnés parce qu'ils sont déjà annoncés
                comme étant précieux et valant la peine d'être présentés comme
                de puissants modèles. Au lieu de cela, la section présente les
                luttes communes et les points forts partagés par les programmes
                au-delà des frontières et souligne le travail acharné dans
                divers contextes. Vous trouverez plus de détails à ce sujet dans
                la section Reflexions Grande image. section.
              </p>
            </div>
          </div>
        </article>
        <article className={'module-featured'} id={'common'}>
          <h1>LUTTES ET FORCES COMMUNES</h1>

          <figure>
            <img
              src="https://i.vimeocdn.com/video/693991407.webp?mw=1440&mh=710&q=70"
              alt="Placeholder"
              width="1440"
              height="710"
            />{' '}
            <i className={'icon-pluses'}></i>
          </figure>
        </article>
        {/* <div>
          <PageMenu menuItems={BLUEPRINT_SUB_MENU} />
        </div> */}
        <article>
          <header className={'text-center'}>
            <h2 style={{ color: 'black' }}>
              LA BASE DE DONNEES PRINCIPALE (783 PROGRAMMES)
            </h2>
          </header>
          <div>
            <h3 style={{ textAlign: 'center' }}>Figure 1</h3>
            <p style={{ textAlign: 'center' }}>
              Base de données principale des programmes par région et type
            </p>
          </div>

          <figure style={{ display: 'flex', justifyContent: 'center' }}>
            <img
              src={DBProgramsByRegion}
              alt="Placeholder"
              width="650"
              height="650"
            />
          </figure>
          <figure style={{ display: 'flex', justifyContent: 'center' }}>
            <img
              src={DBProgramsByType}
              alt="Placeholder"
              width="650"
              height="650"
            />
          </figure>

          <div>
            <h3 style={{ textAlign: 'center' }}>Tableau 1</h3>
            <p style={{ textAlign: 'center' }}>
              Programmes de base de données par région et par type
            </p>
          </div>

          <figure style={{ display: 'flex', justifyContent: 'center' }}>
            <img
              src="http://images.ctfassets.net/fhwlk5jsnns3/5ZAzCtNSMWdtwlrbqH7GOV/68580018121307eb641899e1a0230f30/FR_table_1"
              alt="Placeholder"
              width="650"
              height="650"
            />
          </figure>

          <div>
            <h3 style={{ textAlign: 'center' }}>Figure 2</h3>
            <p style={{ textAlign: 'center' }}>
              Programmes de INN Partner par région et par type
            </p>
          </div>

          <figure style={{ display: 'flex', justifyContent: 'center' }}>
            <img
              src={INNPartnerByRegion}
              alt="Placeholder"
              width="650"
              height="650"
            />
          </figure>
          <figure style={{ display: 'flex', justifyContent: 'center' }}>
            <img
              src={INNPartnerByType}
              alt="Placeholder"
              width="650"
              height="650"
            />
          </figure>

          <div>
            <h3 style={{ textAlign: 'center' }}>Tableau 2</h3>
            <p style={{ textAlign: 'center' }}>
              Programmes de INN Partner par région et par type
            </p>
          </div>

          <figure style={{ display: 'flex', justifyContent: 'center' }}>
            <img
              src="http://images.ctfassets.net/fhwlk5jsnns3/jziZxZ9nUB1bHqen2bRN4/478ff35de95cff8ed7dd4dd2245158b4/FR_Table_2"
              alt="Placeholder"
              width="650"
              height="650"
            />
          </figure>
        </article>
        <article className={classnames('module-wide', 'overlay-b')}>
          <div id={'alternatives'}>
            <header className={'text-center'}>
              <h2 style={{ color: 'black' }}>ALTERNATIVES À L'INCARCÉRATION</h2>
            </header>
            <div className={classnames('double', 'a', 'hr')}>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  LUTTE COMMUNE:
                </h3>
                <p>
                  Au niveau mondial, il existe une pénurie d'alternatives aux
                  programmes d'incarcération (ATIs). Le RNI a beaucoup plus de
                  services directs et de programmes de plaidoyer / politique que
                  les ATIs; sur l'ensemble des programmes de la base de données
                  au moment de l'évaluation des données, seuls 14% (111
                  programmes) étaient des accès à l'information (contre 60% de
                  services directs et 26% de plaidoyers / politiques). Parmi les
                  participants à INN Partner, 63% des ATIs ont déclaré que leur
                  objectif immédiat et à court terme était de « piloter des
                  pratiques de JR», suggérant un fort besoin de développement et
                  de soutien de nouveaux programmes dans ce domaine.
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  LUTTE COMMUNE:
                </h3>
                <p>
                  Près d’un tiers (31,1%) des programmes de INN Partner ATI ont
                  désigné le principal problème qu’ils visent à traiter comme
                  suit: «Discrimination / surreprésentation de certains groupes
                  (sur la base de la culture ou de la race autochtones) dans les
                  prisons».
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  LUTTE COMMUNE:
                </h3>

                <p>
                  Là où existent de puissants ATIs (en particulier des
                  programmes de justice réparatrice), ils sont presque toujours
                  réservés aux mineurs. Il semble y avoir beaucoup de réticence
                  à donner ces opportunités aux adultes, qui, après tout,
                  étaient jadis des mineurs confrontés aussi à un système
                  préjudiciable.
                </p>
                <figure>
                  <img
                    style={{ maxHeight: '200px', objectFit: 'cover' }}
                    src="https://cdhep.org.br/wp-content/uploads/2019/04/Curso_V%C3%ADtima_Ofensor_Comunidade_1.jpg"
                    alt="Placeholder"
                    width="415"
                    height="200"
                  />
                </figure>
              </div>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  FORCE COMMUNE:
                </h3>
                <p>
                  Les programmes d'ATI existent depuis longtemps: en moyenne 18
                  ans - les programmes les plus anciens des trois grandes
                  catégories examinées dans INN Partner. Les programmes d’ATI
                  ont également une vaste portée et représentent le plus grand
                  nombre moyen de participants des trois catégories de
                  programmes. Parmi les programmes INN Partner, l’ATI compte
                  parmi le personnel le plus nombreux, mais elle dépend
                  également beaucoup de bénévoles; en moyenne, 30% des personnes
                  impliquées dans les organisations ne sont pas rémunérées. Il
                  est également plus probable que les personnes qui ont
                  participé à une ATI soient incarcérées ou ont déjà été
                  incarcérées (30% des programmes).
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  FORCE COMMUNE:
                </h3>
                <p>
                  Pour la moitie des ATIs, La vision transformatrice à long
                  terme du changement était la suivante: «Peine alternative /
                  Justice réparatrice sont la principale réponse aux crimes ou
                  aux condamnations».
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  FORCE COMMUNE:
                </h3>
                <p>
                  les ATI sont nettement meilleur marché que les programmes de
                  services directs (calculés soit comme coût global du
                  programme, soit comme coût par bénéficiaire). Les coûts
                  annuels d'un programme varient de 4000 à 15 millions USD, mais
                  l'alternative la plus chère au programme d'incarcération est
                  trois fois moins chère que le programme de services directs le
                  plus coûteux.
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div id={'restorative'}>
            <header className={classnames('text-center')}>
              <h2 style={{ color: 'black' }}>LA JUSTICE RÉPARATRICE</h2>
            </header>
            <div className={classnames('double', 'a', 'hr')}>
              <div>
                <p>
                  <span className="strong">REMARQUE: </span> De nombreux
                  programmes d’ATI impliquent des aspects de la justice
                  réparatrice et de la jurisprudence thérapeutique (en
                  Nouvelle-Zélande, par exemple, le Sentencing Act de 2002
                  contient des dispositions claires en matière de justice
                  réparatrice qui obligent les juges à prendre en compte les
                  processus réparateurs). INN a classé un programme en tant que
                  programme JR uniquement s’il s’est explicitement identifié
                  comme tel.
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  LUTTE COMMUNE:
                </h3>
                <p>
                  Dans de nombreuses régions du monde, la JR est encore un
                  concept inconnu ou vague pour le grand public - elle fait face
                  à une crise des relations publiques. Un programme américain a
                  dû cesser de qualifier son travail de « conférence
                  communautaire », car il était confondu avec une entreprise qui
                  loue des espaces de conférence ; ils ont également mentionné
                  qu'ils étaient toujours une option inconnue pour de nombreuses
                  écoles et systèmes de justice de la région, alors qu'ils
                  existaient depuis plus de deux décennies.
                </p>
              </div>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  FORCE COMMUNE:
                </h3>
                <p>
                  Des facilitateurs puissants qui s'engagent dans ce que la
                  réponse réparatrice Baltimore (États-Unis) appelle un « modèle
                  de croissance continue» sont essentiels au succès de toute
                  intervention de JR, par opposition à des formations larges et
                  uniques destinées aux facilitateurs, qui diluent souvent le
                  processus ou les concepts, et forment des groupes de personnes
                  qui peuvent ne pas convenir parfaitement au rôle de
                  facilitateur très nuancé.
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  FORCE COMMUNE:
                </h3>
                <p>
                  Les programmes de justice réparatrice ont été sélectionnés
                  pour la plate-forme INN Partner, en partie parce qu'ils
                  détournent les gens et / ou contournent le système de justice
                  pénale traditionnel au lieu de servir d’additif (exemple:
                  programmes mettant en œuvre des personnes déjà incarcérées).
                  RNI se sent plus enthousiasmé par ces programmes, car ce sont
                  des approches de la justice non basées sur les prisons.
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div id={'diversion'}>
            <header className={classnames('text-center')}>
              <h2 style={{ color: 'black' }}>DIVERSION</h2>
            </header>
            <div className={classnames('double', 'a', 'hr')}>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  LUTTE COMMUNE:
                </h3>
                <p>
                  De nombreux programmes de déjudiciarisation impliquent de
                  renvoyer des personnes vers des programmes externes au lieu de
                  la prison (traitement de la toxicomanie / alcoolisme,
                  mentorat, services de traitement du deuil, etc.); à cette fin,
                  43% des ATIs ont identifié leur principale clé de succès comme
                  étant «une collaboration avec des partenaires externes».
                  Cependant, de nombreuses organisations affirment que de tels
                  programmes manquent de ressources et / ou manquent à divers
                  égards, en particulier en ce qui concerne les sensibilités
                  culturelles vis-à-vis des groupes surreprésentés. .Aboriginal
                  Legal Services (Canada) répond à ce problème par une approche
                  intéressante: devenir un guichet unique, créer des programmes
                  internes conçus pour et par les peuples autochtones.
                </p>
                <img
                  className={'desktop-only'}
                  src="https://www.incarcerationnationsnetwork.com/wp-content/uploads/2016/02/bxp41924s.jpg"
                  alt="Placeholder"
                  width="400"
                  height="625"
                />
              </div>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  FORCE COMMUNE:
                </h3>
                <p>
                  Des plans de déjudiciarisation personnalisés et holistiques
                  sont essentiels - il n’existe pas de formule unique. Dans le
                  cadre de programmes solides, différentes agences travaillent
                  en synergie, avec une connaissance personnalisée de chaque
                  personne devant elles et en collaboration avec les familles,
                  les travailleurs sociaux, les systèmes d’éducation et de
                  santé. En Nouvelle-Zélande, par exemple, les conférences de
                  groupes familiaux, le tribunal de la jeunesse et le Rangatahi
                  travaillent ensemble pour créer des plans personnalisés. Le
                  centre de justice communautaire de Red Hook (États-Unis) se
                  dit «centré sur la personne» et ne classe pas les clients même
                  en fonction du type de crime. Aux Pays-Bas, Halt voit 16 000
                  jeunes par an - un tiers de tous les enfants arrêtés dans le
                  pays - mais parvient néanmoins à créer des plans de
                  détournement individualisés, chaque processus prenant en
                  moyenne trois mois. Le programme est en constante évolution et
                  innovation, passant à la prévention en travaillant dans les
                  écoles, en expérimentant des travaux sur différents groupes
                  d’âge et en utilisant le jeu et la RV dans les tâches
                  d’apprentissage. 75% de ceux qu’il détourne ne commettent pas
                  de nouvelles infractions.
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  FORCE COMMUNE:
                </h3>
                <p>
                  Les tribunaux autochtones de la Nouvelle-Zélande et
                  d’Australie, ainsi que le Red Hook Community Justice Centre
                  (États-Unis), révèlent les moyens parfois petits et subtils
                  par lesquels le cadre juridique peut être humanisé et adapté à
                  la culture afin de refléter ce que les fondateurs du Koori
                  Court, en Australie, appelle «la tradition au lieu de la loi»:
                  absence de formalités excessives; donner à l'accusé une voix
                  forte et un rôle central dans le processus; accent mis sur la
                  communauté par le biais de rituels, de chants, du toucher des
                  mains et du visage, ainsi que de la présence vitale de
                  vieilles personnes ou de personnes respectées; parlant un
                  anglais simple et non pas un «jargon juridique»; supprimer les
                  barrières physiques construites par la loi occidentale
                  (blanche) et créer une disposition de sièges qui en soit le
                  reflet, par opposition aux hiérarchies et au pouvoir
                  (c'est-à-dire que le juge ne siège pas sur une plate-forme
                  surélevée); prendre du temps avec le processus au lieu de se
                  précipiter dans un style de justice à la chaîne; en accordant
                  une attention particulière à la conception des salles
                  d’audience: présence de lumière naturelle et / ou de peintures
                  murales accueillantes en évitant le bois sombre; en utilisant
                  tout au long un langage respectueux ("s'il vous plaît" et
                  "merci") sur la signalisation.
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div id={'mediation'}>
            <header className={classnames('text-center')}>
              <h2 style={{ color: 'black' }}>LA MÉDIATION</h2>
            </header>
            <div className={classnames('double', 'a', 'hr')}>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  LUTTE COMMUNE:
                </h3>
                <p>
                  Dans les pays du monde entier, les systèmes juridiques sont
                  saturés, les avocats (ou les avocats des clients qui ne
                  peuvent pas payer) sont rares - et la meilleure réponse est
                  une approche ascendante de la justice, par opposition à une
                  approche descendante, approche de la justice: détourner les
                  gens avant même qu’ils n’entrent dans la sphère juridique. Les
                  avocats officiels ne sont souvent pas nécessaires pour avancer
                  vers des résolutions ; L’habilitation juridique et les
                  artisans de paix communautaires - comme ceux du programme de
                  médiation de Madaripur au Bangladesh et du programme de
                  rétablissement de la paix du Centre de justice communautaire
                  de Red Hook (États-Unis), dans lesquels les artisans de la
                  paix sont formés par la nation Navajo - sont donc vitaux.
                  L’élaboration d’initiatives de rétablissement de la paix
                  soutenues par le gouvernement, telles que celle du centre de
                  justice communautaire de Red Hook, est un domaine propice à la
                  croissance.
                </p>
              </div>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  FORCE COMMUNE:
                </h3>
                <p>
                  Dans la société Navajo,
                  <span style={{ fontStyle: 'italic' }}> k’e</span> est un
                  système de parenté qui repose sur des valeurs telles que le
                  respect, la gentillesse, la coopération, la convivialité et
                  les relations réciproques. Les comportements qui bafouent les
                  règles du k’e peuvent laisser la personne « agir comme s’il
                  n’a pas de parents ». Les programmes de médiation réussis sont
                  en mesure de puiser dans ces valeurs communautaires et
                  familiales afin d’instaurer la paix.
                </p>
              </div>
            </div>
          </div>

          <hr />
          <div id={'advocacy'}>
            <header className={classnames('text-center')}>
              <h2 style={{ color: 'black' }}>POLITIQUE / PLAIDOYER</h2>
            </header>
            <div className={classnames('double', 'a', 'hr')}>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  LUTTE COMMUNE:
                </h3>
                <p>
                  Partout dans le monde, les organisations de réforme
                  pénitentiaire préconisent un grand nombre de choses identiques
                  : 1. Réformer les politiques relatives aux infractions
                  mineures et / ou à la lutte draconienne contre la drogue. 2.
                  Réforme de la peine ; 3. amélioration des conditions de
                  détention ; 4. Politique avant le procès / sous caution. Cette
                  dernière (politique avant le procès) est particulièrement
                  pertinente sur le plan mondial, citée par 50% des programmes
                  de plaidoyer de INN Partner comme l’un des domaines de
                  politique les plus importants qu’ils cherchent à influencer.
                </p>
                <img
                  style={{
                    minHeight: '295px',
                    minWidth: '100%',
                    objectFit: 'cover',
                  }}
                  src="https://www.incarcerationnationsnetwork.com/wp-content/uploads/2016/02/8109-org.jpg"
                  alt="Placeholder"
                  width="360"
                  height="295"
                />
              </div>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  FORCE COMMUNE:
                </h3>
                <p>
                  De nombreuses organisations utilisent les mêmes méthodes pour
                  traiter les problèmes ci-dessus : 1. Engagement avec les
                  décideurs ; 2. Litige stratégique; 3. Changer le discours
                  public via des campagnes médiatiques et / ou artistiques; 4.
                  Produire des recherches et des rapports. En ce qui concerne la
                  raison d'être du programme, 65% des programmes ont déclaré «
                  Si les personnes occupant des postes de décision avaient des
                  informations plus précises, elles prendront de meilleures
                  décisions », tandis que 45% ont déclaré «Si les personnes
                  occupant des postes de décision ont plus d'empathie pour la
                  vie de l’homme et son histoire affectées par son choix, ils
                  prendront de meilleures décisions.”
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  FORCE COMMUNE:
                </h3>
                <p>
                  Certaines questions d’importance capitale pour un litige
                  stratégique transfrontalier sont très importantes: réforme de
                  la caution; les obstacles à l'éducation, à l'emploi et au
                  logement pour les personnes anciennement incarcérées (connu
                  aux États-Unis sous le nom de «mouvement d'interdiction de la
                  boîte»); la question des vacances judiciaires pendant lesquels
                  les juges en congé ou en pénurie pendant des mois entrave le
                  système et emballe les prisons. Selon RNI, la justice ne part
                  jamais en vacances.
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div id={'directServices'}>
            <header className={classnames('text-center')}>
              <h2 style={{ color: 'black' }}>SERVICES DIRECTS</h2>
            </header>
            <div className={classnames('double', 'a', 'hr')}>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  LUTTE COMMUNE:
                </h3>
                <p>
                  «Le manque de programmes de réhabilitation existants» a été
                  identifié par 20% des 81 programmes de service de INN Partner
                  comme étant le principal problème ou besoin auquel leur
                  programme s'adresse. Pourtant, dans la base de données, 60%
                  des programmes dans le monde entrent dans la catégorie de «
                  services directs » (voir Figure 1); parmi ceux-ci, la plus
                  grande concentration de programmes appartiennent à la
                  catégorie «réintégration» (153 programmes, 20% de tous les
                  programmes) et «autres services de réadaptation» (79
                  programmes, 10% de tous les programmes). Seulement 14% de tous
                  les programmes (111) relèvent de la catégorie des solutions
                  Alternatives à l'incarcération. Une conclusion à en tirer :
                  trop souvent, les efforts visant à sauver des vies pour
                  réparer le fouillis de l'incarcération via des services
                  directs - en particulier en ce qui concerne la réinsertion -
                  occultent, absorbent les ressources des efforts systémiques
                  généralisés visant à l'annuler. Et pourtant, ces efforts de
                  service direct ne sont toujours pas suffisants, les
                  organisations constatant néanmoins un manque.
                </p>
              </div>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  FORCE COMMUNE:
                </h3>
                <p>
                  Les multiples composantes du travail de service direct se
                  chevauchent de manière persistante ; il est pratiquement
                  impossible de faire face à un seul des besoins d’une personne
                  impliquée dans la justice sans faire face simultanément à de
                  nombreux autres besoins. Pour cette raison, il est essentiel
                  de disposer d’une équipe multidisciplinaire ou holistique.
                  AJEM (Liban), par exemple, héberge des avocats, des
                  travailleurs sociaux et des psychologues afin d'adopter ce
                  qu'ils décrivent comme une « approche multidimensionnelle
                  fondée sur la prévention, la réhabilitation et la réinsertion
                  sociale ». Le centre de justice communautaire Red Hook
                  (États-Unis) est à la fois multidimensionnel et
                  multi-juridictionnel., collaborant non seulement avec le
                  bureau du procureur et les tribunaux, mais également avec les
                  services du logement, de l'éducation, de la santé et les
                  services juridiques, gouvernementaux et non gouvernementaux.
                </p>
              </div>
            </div>
            <hr />
            <div>
              <header className={classnames('text-center')}>
                <h2 style={{ color: 'black' }}>ASSISTANCE JURIDIQUE / BAIL</h2>
              </header>
              <div className={classnames('double', 'a', 'hr')} id={'legal'}>
                <div>
                  <h3 style={{ color: 'black', fontSize: '18px' }}>
                    LUTTE COMMUNE:
                  </h3>
                  <p>
                    Dans de nombreux pays, en particulier dans les pays du Sud,
                    la loi agit comme une force néo-coloniale invisible,
                    gouvernant la vie des gens sans qu’ils ne sachent même pas
                    comment elle fonctionne. De nombreux systèmes juridiques du
                    monde ayant des racines coloniales se sont superimposés à un
                    contexte communautaire pour lequel ils sont extrêmement mal
                    adaptés ; la synecdoche parfaite pour cela est l'image d'un
                    juge africain dans un pays comme le Ghana, vêtu d'une robe
                    formelle et d'une vieille perruque blanche britannique, dans
                    une salle d'audience étouffante, loin de l'Angleterre. Comme
                    MyJustice Myanmar nous le rappelle, «la loi» signifie
                    «mentir» dans la langue karen. Des organisations comme
                    CHREAA (Malawi), MyJustice (Myanmar) et Advocaid (Sierra
                    Leone) s'emploient à rendre la loi accessible et
                    compréhensible en utilisant le théâtre et l’art
                    communautaires pour atteindre les masses, en particulier
                    dans les zones rurales.
                  </p>
                  <img
                    style={{
                      minHeight: '295px',
                      minWidth: '100%',
                      objectFit: 'cover',
                    }}
                    src="https://advocaidsl.org/wp-content/uploads/2018/12/IMG_0774.jpg"
                    alt="Placeholder"
                    width="360"
                    height="295"
                  />
                </div>
                <div>
                  <h3 style={{ color: 'black', fontSize: '18px' }}>
                    FORCE COMMUNE:
                  </h3>
                  <p>
                    Dans le monde entier, les organisations utilisent plusieurs
                    approches communes pour aider et réduire les populations
                    carcérales, en particulier les personnes en détention
                    provisoire. Ces approches incluent: 1. Fournir une
                    assistance juridique aussi tôt que possible dans le
                    processus (dans les commissariats de police en Inde via
                    CHRI, par exemple), ainsi que divers types de services
                    complémentaires (Advocaid, Sierra Leone; Brooklyn Defender
                    Services, États-Unis); 2. Développer des évaluations des
                    risques tenant compte des différences culturelles pour
                    libérer les personnes de la détention provisoire (JJAI,
                    Mexique); 3. Recours à des tribunaux mobiles et à une
                    formation juridique derrière les barreaux pour libérer les
                    personnes de leur détention (programme Justice pour tous,
                    Ghana); 4. Générer des fonds de caution et plaider en faveur
                    d'une réforme de la liberté sous caution (The Bail Project,
                    États-Unis); 5. Développer et accréditer les avocats et les
                    parajuristes incarcérés pour éliminer l‘arriéré de cas
                    (Projet des prisons africaines, Kenya et Ouganda); 6.
                    Fournir une aide juridique ainsi que des compétences en
                    matière d'éducation juridique et d'autonomisation (CHREAA,
                    Malawi).
                  </p>
                  <h3 style={{ color: 'black', fontSize: '18px' }}>
                    FORCE COMMUNE:
                  </h3>
                  <p>
                    Les organisations qui adoptent une approche globale de
                    l'assistance juridique, telles que Brooklyn Defender
                    Services (États-Unis) et Advocaid (Sierra Leone), offrent de
                    nombreux types de services sociaux à leurs clients,
                    reconnaissant que ceux qui sont pris au piège du système de
                    justice pénale ont souvent des besoins. qui vont au-delà de
                    l'assistance juridique.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div id={'reentry'}>
            <header className={classnames('text-center')}>
              <h2 style={{ color: 'black' }}>RÉENTRÉE</h2>
            </header>
            <div className={classnames('double', 'a', 'hr')}>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  LUTTE COMMUNE:
                </h3>
                <p>
                  «Réentrée / réintégration» est la catégorie de programme la
                  plus importante des listes INN Partner et Base de données,
                  suggérant à quel point le problème est terrible au niveau
                  mondial et combien de ressources sont dépensées pour y
                  remédier. 34% des programmes Inn Partner Direct Service,
                  toutes variétés confondues, ont pour objectif «Améliorer les
                  conditions, les ressources et les perspectives de
                  réintégration après la libération» (27% le désignent comme
                  suit: «Améliorer le bien-être et les compétences des personnes
                  incarcérées pendant leur emprisonnement”).
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  LUTTE COMMUNE:
                </h3>
                <p>
                  Les obstacles profonds à l’emploi, au logement et à
                  l’éducation des citoyens de retour sont des réalités
                  mondiales. Pourtant, les efforts visant à supprimer ces
                  obstacles - à la fois juridiques (via des litiges stratégiques
                  et autres travaux de plaidoyer) et culturels (en modifiant le
                  discours sur les personnes incarcérées et l'acceptation de la
                  communauté) - sont rares presque partout sauf aux États-Unis,
                  où ils sont connus collectivement comme les " Mouvement
                  «Interdire la boîte» (en vous référant à la case «avez-vous un
                  casier judiciaire?», Que les candidats doivent cocher sur les
                  demandes) Même dans des pays tels que les Pays-Bas, où il
                  n’existe pas d’énormes obstacles Les citoyens de retour
                  agissent en pensant à tort qu'ils le font et sont donc
                  réticents à saisir les occasions qui se présentent. Les
                  barrières informelles, telles que la stigmatisation et la
                  honte, prévalent et sont difficiles à quantifier.
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  LUTTE COMMUNE:
                </h3>
                <p>
                  A l’exception de Singapour, les gouvernements manquent
                  d’unités robustes de réentrée, tant au niveau local que
                  national, et de bases de données sur les emplois de
                  réinsertion (similaires à celles de Singapour, qui compte 5520
                  employeurs enregistrés). C'est un grand besoin et une
                  croissance potentielle.
                </p>
                <img
                  style={{ minHeight: '625px', objectFit: 'cover' }}
                  className={'desktop-only'}
                  src="https://www.score.gov.sg/images/default-source/module/home-content/63840343-ee9f-4340-9b5d-c3215518b279.jpg"
                  alt="Placeholder"
                  width="400"
                  height="625"
                />
                <img
                  className={'desktop-hide'}
                  style={{
                    minHeight: '295px',
                    minWidth: '100%',
                    objectFit: 'cover',
                  }}
                  src="https://www.score.gov.sg/images/default-source/module/home-content/63840343-ee9f-4340-9b5d-c3215518b279.jpg"
                  alt="Placeholder"
                  width="360"
                  height="295"
                />
              </div>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  FORCE COMMUNE:
                </h3>
                <p>
                  Deux choses sont particulièrement fondamentales pour des
                  programmes de réinsertion réussis: un continuum qui commence
                  pendant l'incarcération d'une personne et se poursuit lorsque
                  cette personne est libérée (Singapour appelle cela «des soins
                  de fond»; ses programmes s'y engagent); et une approche
                  holistique des défis universels de la réinsertion: trouver un
                  logement, obtenir un emploi, obtenir un soutien émotionnel et
                  post-traumatique de la part des gens. Ces facteurs distinguent
                  la catégorie « Réentrée» des autres «Services directs»
                  derrière les barreaux, qui se concentrent uniquement sur le
                  développement du travail derrière les barreaux. RNI est
                  fermement convaincu que tout travail qui commence dans les
                  prisons doit se poursuivre en dehors des prisons, en
                  travaillant avec les personnes après leur libération.
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  FORCE COMMUNE:
                </h3>
                <p>
                  L'ingrédient le plus critique de tout programme de réinsertion
                  est: les personnes. Aucune application ou algorithme ne peut
                  se substituer à des êtres humains travaillant étroitement pour
                  aider les personnes rentrant de prison. SCORE (Singapour)
                  dispose d’une équipe dynamique de coaching d’emplois mobiles
                  qui rend visite à ses clients et les accompagne au fur et à
                  mesure qu’ils s’adaptent à la vie active (et contribuent
                  probablement à son taux de maintien de 81%). Le Centre de
                  restauration communautaire NSW (Australie) utilise un « modèle
                  de gestion de cas intensif », qui implique, comme le dit son
                  personnel, «de ne pas être aussi clinique» en engageant un
                  dialogue constant avec les citoyens de retour et de vérif» :r
                  avec eux via des téléphones cellulaires au travail
                  régulièrement. Les logements post-prison gérés par Exodus
                  (Pays-Bas) ont des mentors résidents qui sont disponibles 24
                  heures sur 24 pour demander de l'amitié et du soutien. « Le
                  dévouement et la passion du personnel» ont été identifiés par
                  28,4% des programmes INN Partner comme l’ingrédient clé le
                  plus populaire du succès du programme; Lors des discussions
                  entre RNI et les clients rentrant de prison, les besoins en
                  assistance personnalisée ont été soulignés à maintes reprises.
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  FORCE COMMUNE:
                </h3>
                <p>
                  Pour beaucoup de gens, la réintégration est un long processus
                  avec beaucoup de hauts et de bas. Cela signifie que
                  l'assistance doit s'étendre sur plusieurs mois, voire un an.
                  Exodus (Pays-Bas) parle d'une sorte de programme de suivi pour
                  ceux qui ont quitté leur logement mais souhaitent tout de même
                  consulter leurs mentors et profiter de l'aide et des
                  opportunités qui leur sont offertes.
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  FORCE COMMUNE:
                </h3>
                <p>
                  Le logement est un défi universel pour les personnes rentrant
                  chez eux après la prison. Il est donc essentiel de faire
                  preuve d'innovation pour générer des opportunités de logement,
                  en particulier dans les villes où les marchés du logement sont
                  encombrés et coûteux. Pension Skejby (Danemark) gère un espace
                  qui abrite à la fois des étudiants et des citoyens de retour,
                  qui partagent des espaces communs et construisent ensemble une
                  communauté. The Homecoming Project (USA) associe les personnes
                  qui ont de la place chez elles pour celles qui reviennent de
                  prison.
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div id={'education'}>
            <header className={classnames('text-center')}>
              <h2 style={{ color: 'black' }}>
                L'ÉDUCATION DERRIÈRE LES BARRES
              </h2>
            </header>
            <div className={classnames('double', 'a', 'hr')}>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  LUTTE COMMUNE:
                </h3>
                <p>
                  De solides programmes d’éducation derrière les barreaux sont
                  essentiels; les niveaux d'éducation et la sécurité de la
                  communauté sont toujours corrélés, ce qui est à la fois du bon
                  sens et de la recherche. Pourtant, ces programmes font
                  cruellement défaut, même dans les pays où ils sont
                  relativement répandus, comme en Europe. Une enquête menée en{' '}
                  <a
                    style={{ color: 'black' }}
                    href="https://antoniocasella.eu/nume/Hawley_UE_education_may13.pdf"
                  >
                    {' '}
                    2013 par sur l'éducation et la formation dans les prisons en
                    Europe
                  </a>{' '}
                  a révélé que dans la majorité des États de l'Union européenne,
                  la participation à l'éducation et à la formation dans les
                  prisons était inférieure à 25%. Les obstacles à la
                  participation comprenaient des expériences négatives
                  antérieures en matière d’éducation ; le fait qu'il y avait
                  plus d'incitations à travailler dans les prisons que dans les
                  programmes d’éducation ; et un nombre limité de places dans
                  ces programmes.
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  FORCE COMMUNE:
                </h3>
                <p>
                  Tous les niveaux d’éducation derrière les barreaux sont
                  essentiels, en particulier en ce qui concerne
                  l’alphabétisation et le calcul. Mais les programmes
                  d'enseignement supérieur ne doivent pas être négligés. Non
                  seulement ils soulèvent la barre en ce qui concerne les idées
                  du grand public sur ce que les détenus sont capables de faire,
                  ils représentent un investissement dans le leadership futur
                  et, encore une fois en plaçant la barre haute, une voie vers
                  de véritables opportunités - plutôt que de simplement
                  survivre. La création d'emplois faibles ou l'emploi salarié ne
                  peuvent pas traiter les inégalités systémiques, mais
                  l'investissement dans une éducation de qualité, des
                  opportunités dynamiques et un leadership visionnaire peuvent
                  être un moyen de progresser vers un changement générateur.
                </p>
                <img
                  style={{
                    minHeight: '295px',
                    minWidth: '100%',
                    objectFit: 'cover',
                  }}
                  src="https://images.squarespace-cdn.com/content/57cd909c197aea06e48be99b/1537440132261-JEW860FOZYJPQ5GB8NNL/IMG_2054.JPG?format=750w&content-type=image%2Fjpeg"
                  alt="Placeholder"
                  width="360"
                  height="295"
                />
              </div>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  FORCE COMMUNE:
                </h3>
                <p>
                  Sur des sondages auprès de clients de trois programmes de
                  formation universitaire derrière des barreaux dans trois pays
                  différents sur trois continents différents (Kenya, États-Unis
                  et Italie), les étudiants incarcérés ont loué leurs programmes
                  dans des termes similaires. Ils ont souligné que
                  l’enseignement supérieur les responsabilisait, leur permettait
                  de voir l’avenir de manière beaucoup plus positive et de
                  croire en leur propre valeur («Poursuivre mes études a affirmé
                  ma valeur et ma conviction qu’il y a une vie après
                  l’incarcération», a déclaré un étudiant NJ-Step ( États-Unis):
                  Un étudiant d’Articolo3 (Italie) a décrit la valeur de la
                  formation universitaire pendant son incarcération: "Confiance
                  en moi, possibilité d’avoir un avenir, voir ma vie en couleurs
                  plutôt qu'en noir et blanc"; un étudiant africain du Prisons
                  Project (Ouganda/Kenya), a parlé de son "changement d'attitude
                  envers la vie et les perceptions aussi, l'autonomisation par
                  l'acquisition de connaissances ... respect pour moi et les
                  autres ainsi que ma dignité renforcée", un autre étudiant de
                  l'APP a noté que "le programme a prouvé en effet que les
                  prisonniers ont des solutions à leurs propres problèmes, tout
                  ce dont ils ont besoin est un soutien»). Les étudiants d’APP
                  (Ouganda, Kenya) et de NJ Step (États-Unis) ont également
                  déclaré que les programmes d’éducation renforçaient également
                  la stabilité de la prison dans son ensemble.
                </p>

                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  FORCE COMMUNE:
                </h3>
                <p>
                  Le RNI estime que les universités ont un rôle vital à jouer
                  dans la création de communautés plus sûres et que les formes
                  novatrices de partenariat prison-université offrent de
                  nombreuses possibilités. Après tout, les universités ont des
                  effectifs en formation - futurs enseignants, assistants
                  sociaux, etc. - prêtes à tirer parti de l'exposition à la
                  population incarcérée, qui profite à son tour du réseautage
                  avec les jeunes et des futurs dirigeants qui construisent leur
                  carrière. En règle générale, les universités sont aussi des
                  entités relativement stables sans liens politiques explicites
                  - chose rare dans les pays confrontés à une instabilité
                  politique et économique; ils sont donc extrêmement utiles pour
                  créer et maintenir des programmes à long terme dans les
                  prisons.
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div id={'jobDevelopment'}>
            <header className={classnames('text-center')}>
              <h2 style={{ color: 'black' }}>
                DÉVELOPPEMENT DE TRAVAIL DERRIÈRE LES BARRES
              </h2>
            </header>
            <div className={classnames('double', 'a', 'hr')}>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  LUTTE COMMUNE:
                </h3>
                <p>
                  Une{' '}
                  <a
                    style={{ color: 'black' }}
                    href="https://prisonpolicy.org/reports/outofwork.html"
                  >
                    étude américaine
                  </a>{' '}
                  a montré qu'en 2018, les personnes anciennement incarcérées
                  étaient au chômage à un taux de chômage supérieur à 27% -
                  supérieur au taux de chômage total des États-Unis au cours de
                  toute période historique et près de cinq fois supérieur que le
                  taux de chômage de la population en général. Bien que des
                  études similaires n'existent pas au niveau mondial, le manque
                  d'emplois pour les personnes qui reviennent de prison est
                  systématiquement considéré comme un point critique par les
                  partenaires du RNI.
                </p>
                <img
                  style={{
                    minHeight: '475px',
                    minWidth: '100%',
                    objectFit: 'cover',
                  }}
                  src="https://static.wixstatic.com/media/b8d0a9_8aae039800624e0b865453feb142b110~mv2_d_4128_2322_s_2.jpg/v1/fill/w_899,h_300,al_c,q_80,usm_0.66_1.00_0.01/b8d0a9_8aae039800624e0b865453feb142b110~mv2_d_4128_2322_s_2.webp"
                  alt="Placeholder"
                  width="360"
                  height="400"
                />
              </div>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  FORCE COMMUNE:
                </h3>
                <p>
                  Les modèles coopératifs (c’est-à-dire Coostafe et Humanitas360
                  au Brésil) sont particulièrement intéressants, car ils
                  permettent aux personnes incarcérées de s’intéresser à leur
                  travail, de se l’approprier, d’impacter leur vie après leur
                  libération et de résister au travail en prison (et au travail
                  forcé). Paradigme qui a une longue histoire globale
                  oppressante.
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  FORCE COMMUNE:
                </h3>
                <p>
                  Des sondages auprès des clients révèlent que de nombreux
                  programmes (par exemple, Nafisika au Kenya) ont l’intention de
                  faire une chose (Nafisika: donner des compétences
                  professionnelles à une personne) mais finissent par faire
                  plusieurs choses qui vont bien au-delà du domaine des
                  compétences professionnelles: favoriser la confiance et
                  l’engagement communautaire, suscitant un intérêt pour
                  l'éducation, aiguisant les relations interpersonnelles et
                  générant de l’empathie.
                </p>

                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  FORCE COMMUNE:
                </h3>
                <p>
                  Les programmes innovants ont pour objectif de former leurs
                  clients à des emplois à forte demande, tels que l'horticulture
                  (Hlumelilisa, Afrique du Sud), le codage (The Last Mile,
                  États-Unis) et l'esprit d'entreprise (Nafisika, Kenya). À
                  l’instar des programmes de réinsertion professionnelle à forte
                  intensité, ils ont également recours à un modèle de pipeline
                  pour aider leurs clients à trouver un emploi dès leur sortie.
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div id={'staffTraining'}>
            <header className={classnames('text-center')}>
              <h2 style={{ color: 'black' }}>PRISON STAFF TRAINING</h2>
            </header>
            <div className={classnames('double', 'a', 'hr')}>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  LUTTE COMMUNE:
                </h3>
                <p>
                  Le fait qu'il s'agisse de la deuxième catégorie la moins
                  peuplée de la liste de la base de données du Services Directs,
                  avec seulement 14 programmes mondiaux axés sur le personnel
                  pénitentiaire, est extrêmement préoccupant. Tout comme une
                  école est aussi forte que ses enseignants, une prison /
                  système de justice est aussi fort que son personnel. Les
                  conditions carcérales, et en particulier l'architecture,
                  suscitent un intérêt croissant dans le monde entier, mais sans
                  changement de culture du personnel (soutien, formation et et
                  recrutement de personnes progressistes par exemple), de tels
                  changements risquent de n'être que superficiels.
                </p>
              </div>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  FORCE COMMUNE:
                </h3>
                <p>
                  Une culture qui tient en haute estime les agents de correction
                  attirera de puissants candidats dans la profession. L’école de
                  formation norvégienne et l’Unlocked Graduates (Royaume-Uni) le
                  reconnaissent, le premier rémunérant ses agents de manière
                  concurrentielle et créant un climat et une culture de travail
                  attrayants ; ce dernier s'efforce de modifier la perception de
                  l'emploi dans les contextes de recrutement universitaire et
                  créent un programme de soutien aux boursiers pour ses recrues.
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div id={'rehabServices'}>
            <header className={classnames('text-center')}>
              <h2 style={{ color: 'black' }}>
                AUTRES SERVICES DE RÉADAPTATION DERRIÈRE LES BARRES
              </h2>
            </header>
            <div className={classnames('double', 'a', 'hr')}>
              <div>
                <img
                  style={{
                    minHeight: '275px',
                    minWidth: '100%',
                    objectFit: 'cover',
                  }}
                  src="http://www.insightprisonproject.org/uploads/1/1/6/0/11602085/1345745274.jpg"
                  alt="Placeholder"
                  width="360"
                  height="275"
                />
              </div>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  FORCE COMMUNE:
                </h3>
                <p>
                  Des sondages menés auprès d’organisations de quatre pays
                  différents sur trois continents (Liban, El Salvador, Belgique,
                  Royaume-Uni) révèlent les avantages communs des diverses
                  activités de réhabilitation menées derrière les barreaux, que
                  ce soit par le théâtre, la résolution de conflits ou la
                  création littéraire. On declare, le plus souvent, que ces
                  programmes construisent la confiance et la cohésion de la
                  communauté: La communauté c’est construire une relation avec
                  les autres. Je suis devenu plus humanitaire ; J'ai commencé à
                  aider les autres sans attendre de retour » (AJEM, Liban); «Le
                  programme nous permet de penser ensemble comme un groupe dans
                  lequel chacun donne son opinion. Cela nous fait coexister en
                  tant qu'amis et auteurs »(Contextos, Le Salvador); «Jusqu'à ce
                  que j’aie commencé avec le programme, je me sentais seul, je
                  n'avais personne à qui parler… Maintenant, je sais que j'ai
                  des gens que je peux rencontrer et à qui parler. Je ne me sens
                  pas si seul » (De Rode Antraciet, Belgique); «J'ai vu un
                  groupe de prisonniers sans aucun lien commun immédiat et
                  devenir leur propre version d'une unité familiale, montrant un
                  soin et une compassion les uns envers les autres qui étaient
                  beau à voir» (LEAP, Royaume-Uni).
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div id={'health'}>
            <header className={classnames('text-center')}>
              <h2 style={{ color: 'black' }}>
                SANTÉ ET SÉCURITÉ DERRIÈRE LES BARRES
              </h2>
            </header>
            <div className={classnames('double', 'a', 'hr')}>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  LUTTE COMMUNE:
                </h3>
                <p>
                  Dans la base de données, seulement 1% des programmes entrent
                  dans cette catégorie, malgré le nombre alarmant d'épidémies de
                  VIH /SIDA et de tuberculose derrière les barreaux, en
                  particulier dans toute l'Afrique et l'Asie du Sud-Est.
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  LUTTE COMMUNE:
                </h3>
                <p>
                  L'impact de la guerre contre la drogue dans la population
                  carcérale mondiale ne peut être surestimé. Alors que la
                  légalisation de la marijuana et l'idée de traiter la
                  toxicomanie comme une crise de santé publique plutôt que de
                  justice pénale - y compris par des approches de réduction des
                  méfaits - gagnent progressivement en importance, cette
                  question a besoin de beaucoup plus de dynamisme pour avoir un
                  impact tangible. C'est un domaine de croissance dans le monde
                  entier ; de sérieuses incursions dans la politique en matière
                  de drogue pourraient réduire les populations carcérales de
                  manière dramatique.
                </p>
              </div>
              <div>
                <img
                  style={{
                    minHeight: '475px',
                    minWidth: '100%',
                    objectFit: 'cover',
                  }}
                  src="https://justdetention.org/wp-content/uploads/2015/10/joke_cropped_hi_res-1024x741.jpg"
                  alt="Placeholder"
                  width="360"
                  height="400"
                />
              </div>
            </div>
          </div>
          <hr />
          <div id={'innovative'}>
            <header className={classnames('text-center')}>
              <h2 style={{ color: 'black' }}>
                ALTERNATIVES INNOVANTES AUX PRISONS TRADITIONNELLES
              </h2>
            </header>
            <div className={classnames('double', 'a', 'hr')}>
              <div>
                <p>
                  <span className="strong">REMARQUE: </span>Dans la base de
                  données, la plus forte concentration de ces solutions
                  innovantes se trouve en Europe.
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  LUTTE COMMUNE:
                </h3>
                <p>
                  Les singuliers « établissements modèles de prisons » - une ou
                  deux exceptions progressives d’un pays à sa règle par ailleurs
                  peu progressiste - sont des indicateurs précieux de ce qui
                  pourrait et devrait être. Mais ils sont dangereux dans la
                  mesure où ils restent une excuse pour un changement
                  généralisé, étayant le système et devenant ainsi un obstacle à
                  la reproduction et au changement systémique. Le cas du centre
                  de détention à petite échelle d'Amsterdam-Ouest, initialement
                  un modèle expérimental de détention ouverte pour les jeunes
                  accusés, est un exemple de la façon dont son fondateur l'a
                  exposé : « un centre minuscule a touché tout un pays »: à
                  partir de 2019, l’établissement devrait être reproduit dans
                  tout le pays, entraînant la fermeture de plusieurs centres de
                  détention pour jeunes et un changement systémique radical.
                </p>
              </div>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  FORCE COMMUNE:
                </h3>
                <p>
                  En réponse à la question «Qu'est-ce qui différencie votre
                  établissement / vos établissements des prisons
                  traditionnelles?», Trois des cinq programmes ont déclaré
                  «Principes ou philosophie de la sanction»; deux sur cinq ont
                  également ont déclaré «Coopération avec les communautés et les
                  individus locaux» et «Plus de niveaux de liberté».
                </p>
                <img
                  style={{
                    minHeight: '375px',
                    minWidth: '100%',
                    objectFit: 'cover',
                  }}
                  src="https://pulitzercenter.org/sites/default/files/styles/node_images_768x510/public/11-17-14/unusual-prison-11.jpg?itok=BrnW17ps"
                  alt="Placeholder"
                  width="360"
                  height="375"
                />
              </div>
            </div>
          </div>
        </article>
        <div id={'bigPicture'}>
          <article className={classnames('module-featured', 'box')}>
            <h2>GRANDES IMAGES À PARTIR DE LA PLATE-FORME</h2>
            <p>
              <span
                className={classnames('strong', 'text-uppercase')}
                style={{ fontStyle: 'italic' }}
              >
                Kua tawhiti ke to haerenga mai, kia kore e haere tonu; He tino
                nui rawa o mahi, kia kore e mahi nui tonu. <br /> <br /> Vous
                êtes allé trop loin pour ne pas aller plus loin ; Vous avez trop
                fait pour ne pas en faire plus.
              </span>{' '}
              <br /> -Sir James Henare (Ngati Hine, 1989)
            </p>
            <figure className={'overlay-d'}>
              <img
                src="https://placehold.it/1440x710"
                alt="Placeholder"
                width="1440"
                height="710"
              />
            </figure>
          </article>
          <article className={'mn'}>
            <div className={classnames('double', 'a', 'tablet-compact')}>
              <div className={'m0'}>
                <ul>
                  <p>
                    <span className="strong">REMARQUE: </span>Les réflexions
                    globales sur la plate-forme et l’état actuel du travail
                    pénitentiaire à l’échelle mondiale s’inspirent des données
                    recueillies à partir de questionnaires, ainsi que des
                    visites de site et des entretiens de Dr. Dreisinger, tant
                    formels qu’informels, avec les organisations et les clients.
                    Des tableaux et graphiques comparatifs de certaines réponses
                    au questionnaire obtenues des programmes INN Partner sont
                    disponibles{' '}
                    <a
                      style={{ color: 'black' }}
                      href="https://assets.ctfassets.net/fhwlk5jsnns3/3gIp85pR7uHBwUW9K23TOb/735ece0f7899f5725ef86d0998c31fe2/Comparative_tables_of_questionnaire_responses_for_selected_programs.pdf"
                    >
                      ici
                    </a>
                    ; une bibliographie pertinente est disponible{' '}
                    <a
                      style={{ color: 'black' }}
                      href="https://assets.ctfassets.net/fhwlk5jsnns3/4qE4rtxCNWUOUdpGizTuoG/a029e92dc23a6c5ad28ba03ed67bc796/GlobalAssessment_Bibilio.docx"
                    >
                      ici
                    </a>
                    .
                  </p>
                  <li>
                    <span className="strong">
                      PENSER À L'EXTÉRIEUR DE LA BOÎTE:{' '}
                    </span>
                    Il n'est pas seulement possible d'être global et local en
                    même temps - il est extrêmement bénéfique de l'être. Quand
                    on sort de son propre système pour examiner des problèmes et
                    des solutions, l’esprit commence à penser radicalement à ce
                    qui est possible, au-delà de ce qui est. Trop souvent, même
                    les travailleurs de la justice les plus progressistes sont
                    étranglés par ce qui existe au lieu d’être inspirés par ce
                    qui pourrait être et libérés par ce qui devrait être.
                    Jusqu'à présent, la trajectoire historique de la pratique
                    carcérale mondiale a impliqué l'exportation par les
                    États-Unis de son modèle de prison - une expérience
                    historique - vers le monde, d'abord au 19ème siècle via le
                    colonialisme, puis au 20ème siècle via la mondialisation.
                    Cela peut être annulé en perturbant le flux d’idées : les
                    approches progressistes américaines et non américaines
                    peuvent être partagées et le processus d'apprentissage peut
                    et devrait fonctionner dans plusieurs directions.
                  </li>
                  <li>
                    <span className="strong">
                      RÉPLICATION DE PROGRAMMES PROMETTEURS:{' '}
                    </span>
                    Cela se produit déjà dans des espaces globaux. La prison
                    norvégienne de Bastoy a été reproduite en République
                    Tchèque, par exemple, et le modèle de médiation de Madaripur
                    au Bangladesh a été reproduit au Malawi. Mais ce processus
                    n’est pas une science exacte et il n’existe pas de justice
                    coupée-collée; il vaut donc mieux parler d'inspiration que
                    de réplication. Les programmes doivent tenir compte des
                    spécificités culturelles même si nous reconnaissons les
                    universalités de la pauvreté, du racisme et de l'injustice
                    au cœur des systèmes pénitentiaires.{' '}
                    <a
                      style={{ color: 'black' }}
                      href="https://theguardian.com/social-enterprise-network/2012/nov/06/social-franchising-how-what-benefits"
                    >
                      Le modèle de franchise sociale
                    </a>{' '}
                    est pertinent ici.
                  </li>
                  <li>
                    <span className="strong">
                      PLUS QU”UN SERVICE DE LA PAROLE:{' '}
                    </span>
                    Les gouvernements du monde entier ont changé de «Département
                    des prisons» en «Département des services correctionnels»,
                    démontrant ainsi ce grand changement de terminologie - mais
                    trop souvent c'est du pur labeur. Les gouvernements
                    investissent encore peu dans les « services correctionnels »
                    et placent plutôt les prisons au bas du baril budgétaire.
                    Parce que les programmes liés aux prisons manquent de
                    soutien public et que le discours dominant est punitif - axé
                    sur le crime plutôt qu’intelligent -, les gouvernements sont
                    peu incités à dépenser davantage pour des services
                    correctionnels et à se concentrer sur des problèmes plus
                    populaires qui produisent des résultats immédiats (et donc
                    méritent leur réélection). Il incombe aux gouvernements de
                    dicter et, faute d’un meilleur terme, vendre une justice
                    intelligente à l’intention du public, et non l’inverse, ce
                    n’est pourtant pas la réalité à l’échelle mondiale.
                  </li>
                  <li>
                    <span className="strong">
                      MANQUE DE RESSOURCES ET DE DURABILITÉ:{' '}
                    </span>
                    Le résultat du scénario alarmant ci-dessus est dévastateur
                    pour le domaine de la justice. Parmi les programmes de INN
                    Partner, le défi le plus souvent identifié était le manque
                    de financement, qu'il soit global ou pluriannuel (69% l'ont
                    identifié). Fonctionnant avec des budgets minimaux, les
                    organisations sont obligées de faire appel à des bénévoles,
                    ce qui n’est pas viable. Les organisations sont également
                    incapables d’avoir une vision globale de leur travail car le
                    manque de financement les laisse se débrouiller seules
                    immédiatement. Ceci, à son tour, empêche une organisation
                    d’être audacieuse, innovante et auto-critique. cela se
                    traduit également par un manque de solidarité et parfois de
                    sensibilisation des organisations effectuant des travaux
                    connexes, même dans leur propre pays - une réalité aggravée
                    par la concurrence pour des financements minimaux.lity
                    intensified by competition for minimal funding pools.
                  </li>
                  <li>
                    <span className="strong">CHANGER LE NARRATIF: </span>
                    Pour s'attaquer à la crise du financement, il est essentiel
                    de changer le discours public sur les prisons et ses
                    habitants, en passant d'un discours de vengeance à un
                    discours de réparation. Lorsque le grand public se rend
                    compte que les prisons ne construisent pas des communautés
                    plus sûres et que la plupart des détenus ont besoin
                    d'assistance, pas de préjudice ni de châtiment, lorsque,
                    dans d’autres termes, la prison devient une cause populaire
                    et non, comme c'est le cas un peu partout dans le monde, un
                    sujet tabou invoquant des réactions punitives : il exigera
                    alors un changement. Les politiciens doivent ensuite tenir
                    compte de leurs propos et investir leur argent là où il se
                    trouve et les bailleurs de fonds deviennent plus enclins à
                    soutenir le travail lié aux prisons. Ce changement de
                    discours s'est déjà produit aux États-Unis. Il y a moins
                    d'une décennie, la réforme des prisons était une cause
                    extrêmement impopulaire. Aujourd'hui, même la plupart des
                    célébrités en font sa plate-forme publique. Le rôle des arts
                    - films, installations artistiques, livres - est vital, car
                    ils sont capables de changer les cœurs et les esprits (des
                    textes comme le documentaire{' '}
                    <span style={{ fontStyle: 'italic' }}>13th</span> nominé aux
                    Oscars, et le best-seller{' '}
                    <span style={{ fontStyle: 'italic' }}>
                      The New Jim Crow
                    </span>{' '}
                    aux États-Unis). Presque tous les partenaires du RNI ont
                    exprimé le souhait que le budget et la bande passante
                    accordent une plus grande attention aux communications et à
                    la messagerie, en amplifiant leur travail et en modifiant le
                    discours public. Ils ont également exprimé leur frustration
                    devant la couverture médiatique sensationnaliste de la
                    criminalité et des problèmes liés aux prisons, qui maintient
                    le grand public dans un état de peur de mode de punition et
                    entrave considérablement leur travail. La force de l’opinion
                    publique est l’une des raisons pour lesquelles une pratique
                    fondée sur des données probantes, bien que de grande valeur,
                    présente toutefois des limites : même lorsque les
                    gouvernements disposent de données et savent très bien ce
                    qui « fonctionne », ils ne peuvent pas agir avec cela à
                    l'esprit si le public électoral est fermement opposé à cela.
                    Dans le monde entier, de puissants programmes deviennent des
                    pions dans la guerre des partis politiques, au détriment de
                    la sécurité des communautés, de la paix et de la justice.
                  </li>
                  <li>
                    <span className="strong">
                      RÉÉCRIRE LES RÉCITS DES COMMUNAUTÉS:{' '}
                    </span>
                    Le changement narratif et la narration sont également
                    essentiels lorsqu'il s'agit de convaincre et d'investir les
                    communautés. Lorsqu'une communauté reconnaît l'identité
                    comme un « ghetto » infesté de crimes, elle devient à l'aise
                    dans ce récit - elle s'y investit. Mais quand elle se voit
                    comme une communauté autonome reconnue pour la paix et la
                    justice, elle travaille avec passion pour maintenir cette
                    identité positive. Le travail de JustReinvest (Australie) à
                    Bourke est très révélateur à cet égard. Par le biais d’un
                    travail de réinvestissement dans la justice, une communauté
                    connue comme l’une des plus pénalisées en Australie a
                    remodelé sa structure sociale et, ce faisant, a récrit et
                    révisé son propre récit ; ce nouveau récit - être la
                    communauté pacifique au lieu de la « dangereuse» - devient
                    alors auto-perpétuant. Les récits que nous nous racontons
                    sur nos identités ont un pouvoir énorme sur nous.
                  </li>
                  <li>
                    <span className="strong">FINANCEMENT PUBLIC: </span>
                    L'objectif des programmes innovants devrait être la
                    systématisation. Après tout, le travail du gouvernement est
                    de générer des communautés justes et de financer les
                    programmes et les structures nécessaires à cette fin. Les
                    chiffres du RNI concernant les sources de financement,
                    cependant, montrent que cette systématisation se produit de
                    manière inégale dans le monde entier. Un peu plus de la
                    moitié (54% du total des programmes INN Partner) est
                    principalement financée par des sources non
                    gouvernementales, bien que les services directs (57%) et les
                    alternatives à l'incarcération (63%) soient toujours
                    principalement financés par le gouvernement{' '}
                    <a
                      style={{ color: 'black' }}
                      href="https://assets.ctfassets.net/fhwlk5jsnns3/3gIp85pR7uHBwUW9K23TOb/735ece0f7899f5725ef86d0998c31fe2/Comparative_tables_of_questionnaire_responses_for_selected_programs.pdf"
                    >
                      (Tableau 3)
                    </a>
                    . Cependant, parmi les programmes principalement financés
                    par le gouvernement, moins de la moitié provient
                    d'allocations budgétaires et le reste de subventions
                    gouvernementales. Le financement public des programmes liés
                    aux prisons dépend fortement de la région. En Amérique du
                    Nord, le financement des programmes est plus égal entre
                    sources gouvernementales et non gouvernementales; en Europe
                    et en Océanie, les programmes étaient principalement (plus
                    de 70%) financés par le gouvernement; entre 70% et 100% des
                    programmes en Asie, en Afrique et en Amérique latine ont été
                    financés par des sources non gouvernementales{' '}
                    <a
                      style={{ color: 'black' }}
                      href="https://assets.ctfassets.net/fhwlk5jsnns3/3gIp85pR7uHBwUW9K23TOb/735ece0f7899f5725ef86d0998c31fe2/Comparative_tables_of_questionnaire_responses_for_selected_programs.pdf"
                    >
                      (Tableau 3)
                    </a>
                    . Là où il y a un financement gouvernemental, de nombreuses
                    organisations ont exprimé leur frustration face aux
                    exigences strictes en termes de nombre et de résultats
                    attendus, ce qui les a empêché de faire un travail de
                    qualité au nom de produire une quantité spécifiée de
                    travail. in the name of producing a specified quantity of
                    work.
                  </li>
                  <li>
                    <span className="strong">
                      SOURCES DE FINANCEMENT CRÉATIVES:{' '}
                    </span>
                    Parmi les programmes principalement financés par le
                    gouvernement dans la plate-forme INN Partner, les donateurs
                    internationaux constituent la catégorie la plus élevée et le
                    secteur privé la plus faible. Les sources de financement
                    créatives, telles que l'investissement à impact et les
                    modèles d'entreprise sociale, n'ont été indiquées dans la
                    recette de financement que par deux organisations. Compte
                    tenu de la pertinence croissante de ces modèles de
                    financement autonomes, qui modifient les systèmes, y penser
                    dans un espace judiciaire devrait être beaucoup plus répandu
                    et devrait être encouragé par le biais d'ateliers et de
                    réunions dynamiques et accessibles.
                  </li>
                  <li>
                    <span className="strong">COLLABORATION: </span>
                    43,2% des programmes INN Partner ont mentionné la
                    «collaboration avec des partenaires (externes)» comme l'un
                    des ingrédients clés de leur succès. Parmi ces partenaires,
                    on compte des responsables politiques locaux, des
                    magistrats, des représentants du gouvernement et des
                    organisations communautaires{' '}
                    <a
                      style={{ color: 'black' }}
                      href="https://assets.ctfassets.net/fhwlk5jsnns3/3gIp85pR7uHBwUW9K23TOb/735ece0f7899f5725ef86d0998c31fe2/Comparative_tables_of_questionnaire_responses_for_selected_programs.pdf"
                    >
                      (voir la figure 3)
                    </a>
                    . Encore une fois, comme indiqué ci-dessus, les travailleurs
                    de la justice du monde entier opèrent généralement dans des
                    silos non collaboratifs. Les gouvernements devraient
                    soutenir les réunions et les tables rondes réunissant divers
                    secteurs engagés dans ce travail et créer des entités
                    unificatrices similaires au réseau CARE de Singapour, qui
                    favorise les synergies entre eux.
                  </li>
                  <li>
                    <span className="strong">AUTRES DÉFIS: </span>
                    Les autres défis importants mentionnés le plus souvent par
                    les programmes INN Partner sont: le manque d'espace ou
                    d'infrastructure pour les activités (parmi les alternatives
                    innovantes aux prisons traditionnelles); Manque de soutien
                    gouvernemental ou politique (pour Alternatives à
                    l'incarcération); et instabilité politique ou roulement
                    (pour les programmes politiques / de plaidoyer).
                  </li>
                  <li>
                    <span className="strong">
                      UNE INNOVATION SIMPLE, MAIS INFLUENTE:{' '}
                    </span>
                    Le travail dans le domaine de la justice exige des
                    innovations radicales. Mais en même temps, réduire et
                    soutenir les populations carcérales ne demande souvent pas
                    de nouvelles idées extrêmes, mais l’engagement et
                    l’investissement nécessaires pour adopter des approches
                    simples mais néanmoins efficaces - telles que les tribunaux
                    itinérants, les programmes de déjudiciarisation, la
                    recherche d’emplois tant que les personnes sont toujours
                    incarcérées. Soutien intensif après la libération, et plus.
                    Dans de nombreux pays également, les programmes de réforme
                    du système pénitentiaire mis en œuvre par le gouvernement
                    fonctionnent en théorie, mais pas en pratique : le langage
                    et le modèle sont présents, mais ils ne sont pas adoptés,
                    principalement par manque de ressources et, donc, d'effort
                    efficace.
                  </li>
                </ul>
              </div>
              <div>
                <ul>
                  <li>
                    <span className="strong">
                      LA BOUTQUE N'EST PAS MAUVAISE:{' '}
                    </span>
                    La meilleure justice est individualisée - ce que JustSpeak
                    en Nouvelle-Zélande qualifie de «sur mesure» - ainsi, plus
                    petite est souvent meilleure, car elle est à la fois plus
                    personnalisée et permet une multiplicité d'approches
                    sensibles à la culture. L'intensification est vitale pour un
                    changement systémique généralisé, mais lorsqu'elle est
                    effectuée sans les ressources appropriées pour soutenir le
                    pouvoir populaire qui est si essentiel au travail en matière
                    de justice - et lorsqu'elle est faite trop tôt, avant qu'un
                    programme ne soit totalement opérationnel - elle peut être
                    désastreuse. Il y a donc de la valeur dans une multiplicité
                    d'approches sur mesure, d'autant plus qu'elles permettent de
                    mener des expériences novatrices et de réflexion. Rescaled,
                    un mouvement européen qui milite pour des centres de
                    détention de petite taille, mérite d’être mentionné, «
                    permet des voies de réinsertion sur mesure, permet une
                    approche plus personnelle, moins de bureaucratie, une
                    sécurité plus dynamique et offre plus de possibilités aux
                    prisonniers pour prendre des responsabilités et interagir
                    avec la communauté. » En matière de prisons et de
                    réinsertion, la boutique n'est pas seulement une mauvaise
                    chose - en fait, plus petit est meilleur parce qu'il permet
                    un travail personnalisé.
                  </li>
                  <li>
                    <span className="strong">
                      ÉCHAPPEMENT DE LA PRATIQUE CLINIQUE:{' '}
                    </span>
                    Les organisations puissantes évitent le langage clinique des
                    fournisseurs de services et leur engagement en faveur d'une
                    interaction humaine normale et d'une relation forte. Les
                    petites maisons de réinsertion d'Exodus (Pays-Bas)
                    n'accueillent donc pas de "clients" mais des "participants”
                    ; ils impliquent de nombreux volontaires qui s'engagent
                    auprès de leurs participants simplement en tant qu'amis et
                    collègues, et non en tant que fournisseurs de services, et
                    ne fournissent pas leurs services de santé mentale en
                    interne, car « nous ne sommes pas une clinique médico-légale
                    ». Ruud Jacobs, fondateur de l'établissement Small Scale
                    Amsterdam West, décrit la méthode de l'établissement comme
                    «non pas une méthode mais un mouvement» et explique que
                    simplement «être présent» et «tout normaliser» va très loin,
                    en particulier pour les jeunes qui ont été «clinique-isés»
                    par des fournisseurs de services sociaux depuis si
                    longtemps. Là-bas, les agents de sécurité ne sont pas des
                    «officiers», mais des «hôtes», également chargés de nouer
                    des relations avec les voisins.
                  </li>
                  <li>
                    <span className="strong">LE PROCESSUS EST ESSENTIEL: </span>
                    Une approche ascendante est toujours préférable à une
                    approche descendante. Les organisations doivent créer des
                    programmes avec les communautés, et non pas pour elles.
                    JustReinvest (Australie) s'est engagé auprès de la ville et
                    en particulier des plus âgés de Bourke en leur donnant les
                    moyens de déterminer où et comment ils souhaitaient voir les
                    investissements communautaires réalisés. ils ne sont pas
                    encore arrivés avec un autre programme prêt à l'emploi, prêt
                    à être adapté à une communauté fatiguée de telles
                    impositions. Le centre de justice communautaire Red Hook a
                    suivi le mantra d’« écouter d’abord» en devenant le deuxième
                    tribunal communautaire de New York, laissant les membres de
                    la communauté décider sur quoi le tribunal devrait se
                    concentrer, où il devrait être situé et quels devraient être
                    ses processus. À cette fin, chacun des programmes du Centre
                    pour l’innovation du tribunal, dont fait partie le centre de
                    justice communautaire Red Hook, est unique en son genre,
                    créé en fonction des besoins particuliers de son lieu et de
                    sa communauté.
                  </li>
                  <li>
                    <span className="strong">OUVERTURE: </span>
                    Plus un local est ouverte - plus il autorise les congés de
                    fin de semaine, de travail, les sorties éducatives et autres
                    - plus elle peut promouvoir l'intégration dans la
                    communauté, essentielle aux efforts de justice progressive.
                    Les défenseurs et les praticiens du monde entier devraient
                    donc envisager de faire pression pour des murs plus poreux -
                    permettant aux personnes incarcérées de participer à des
                    programmes dans la communauté - plutôt que de construire
                    davantage de programmes derrière les murs.
                  </li>
                  <li>
                    <span className="strong">PRINCIPES DIRECTEURS: </span>
                    Parmi les clients des organisations mondiales, les mêmes
                    mots ont été répétés dans le contexte de la description de
                    programmes forts: confiance, autonomisation, famille,
                    respect de la vision à long terme du changement
                    transformationnel, soutien, aide, convivialité, motivation,
                    autonomisation, choix, responsabilité, confiance,
                    transformation. Le langage sur le «sentiment humain» dû à un
                    programme puissant était également récurrent, tout comme la
                    mention de la communauté et la vitalité de la dynamique de
                    groupe. Les mêmes mots ont également été répétés pour
                    définir leur travail: justice, responsabilité, innovation,
                    respect, dignité, communauté (Figure 4).
                  </li>

                  <div>
                    <h3 style={{ textAlign: 'center' }}>Figure 4</h3>
                    <p style={{ textAlign: 'center' }}>
                      Adjectifs décrivant les principes directeurs de
                      l'organisation
                      <br />
                      <span style={{ fontStyle: 'italic' }}>
                        Veuillez énumérer jusqu'à cinq adjectifs qui
                        représentent le mieux les principes directeurs et les
                        valeurs qui sous-tendent votre organisation.
                      </span>
                    </p>
                  </div>
                  <figure style={{ display: 'flex', justifyContent: 'center' }}>
                    <img
                      src="https://images.ctfassets.net/fhwlk5jsnns3/2QjBoGQA2EcVd8dzOCpWkV/2c72e8025545f2c7ba36dbc1048eaaf7/wordMap.png"
                      alt="Placeholder"
                      width="650"
                      height="650"
                    />
                  </figure>
                  <li>
                    <span className="strong">
                      CHANGEMENT IMMEDIAT ET SYSTEMIQUE:{' '}
                    </span>
                    Des programmes particulièrement passionnants sont à la fois
                    systémiques et individualistes. Autrement dit, ils sont en
                    mesure d'offrir des services directs aux personnes
                    immédiatement tout en préconisant et en œuvrant en faveur
                    d'un changement systémique pouvant rendre leur travail
                    inutile. Les programmes le font de différentes manières. Le
                    Project Africain des prisons (Kenya, Ouganda) accorde des
                    diplômes et des certificats en droit, mais leur travail a
                    également abouti à l'abrogation de la peine de mort
                    obligatoire dans les deux pays, grâce au travail de
                    plaidoyer de leurs étudiants; en incluant des agents
                    pénitentiaires dans leurs classes aux côtés des étudiants
                    incarcérés, ils modifient également fondamentalement la
                    culture du personnel pénitentiaire, ce qui pourrait conduire
                    à un changement plus systémique. Juvenile Justice Advocates
                    International et Equis (Mexique) s’engagent principalement
                    dans l’élaboration de politiques mais aussi dans le cadre de
                    projets liés au service direct, leur permettant non
                    seulement d’aider les clients, mais également d’engager des
                    volontaires et de les exposer aux prisons et aux personnes
                    qui sont à l'intérieur, ce qui contribue au changement de
                    culture. Genepi (France) engage des étudiants de tout le
                    pays à organiser des ateliers dans les prisons, qui offrent
                    un service aux personnes incarcérées mais permettent
                    également d'exposer les jeunes à une population carcérale
                    humanisée et de leur inculquer une passion pour la justice -
                    une autre forme de changement de culture. Il est intéressant
                    de noter que seuls huit des programmes INN Partner ont
                    déclaré que leur vision à long terme du changement
                    consistait à «démanteler les prisons»; “Réduire de manière
                    drastique la population carcérale” était le plus souvent
                    cité (21 programmes){' '}
                    <a
                      style={{ color: 'black' }}
                      href="https://assets.ctfassets.net/fhwlk5jsnns3/3gIp85pR7uHBwUW9K23TOb/735ece0f7899f5725ef86d0998c31fe2/Comparative_tables_of_questionnaire_responses_for_selected_programs.pdf"
                    >
                      (voir tableau 4)
                    </a>
                    .
                  </li>
                  <li>
                    <span className="strong">LE POUVOIR DES PERSONNES: </span>
                    En dépit des «recettes» indiquées pour un travail fort,
                    est-il important de noter que les systèmes et les structures
                    définissent la justice - mais que les gens donnent vie aux
                    systèmes? Une seule personne peut ainsi avoir un impact
                    énorme sur tout un système de justice - le RNI l'a vu du
                    Costa Rica et de la République Tchèque à l'Italie, aux
                    Pays-Bas et au Mexique, où des alternatives innovantes aux
                    prisons traditionnelles (dans les quatre premiers pays) et
                    des changements importants la détention provisoire (au
                    Mexique) a vu le jour parce qu'un membre d'un organisme
                    judiciaire était déterminé à les voir arriver, contre toute
                    attente et à tout prix; la théorie moderne des réseaux
                    suggère l’effet en chaîne d’une seule personnes et d’une
                    seule action. Ce concept de « qui travaille et non pas ce
                    qui fonctionne » s'applique également lorsque nous
                    considérons les ingrédients de programmes forts. Les
                    partenaires du RNI ont souligné à maintes reprises
                    l'importance de disposer d'un personnel fort, en particulier
                    - comme dans l’établissement Small Scale d'Amsterdam West,
                    où six de leurs sept employés sont marocains ou surinamiens
                    - personnels qui appartiennent à des milieux culturels /
                    communautés similaires à ceux de leurs clients.
                  </li>
                  <li>
                    <span className="strong">
                      CONSTRUIRE DES DIRIGEANTS DIRECTEMENTINFLUENTS:{' '}
                    </span>
                    Investir dans un changement systémique signifie également
                    former des leaders dans les domaines du travail social et de
                    l'activisme - en particulier des leaders culturellement
                    divers et directement touchés, qui ont vécu la connaissance
                    des problèmes et des solutions et sont donc une banque de
                    sagesse et non comme ils sont trop souvent vu, un bassin de
                    problèmes. Il est essentiel que les personnes ayant une
                    expérience vécue guident le travail de programmation, mais
                    en moyenne moins de 15% du personnel des programmes INN
                    Partner étaient des personnes incarcérées ou anciennement
                    incarcérées. Au total, 30% des programmes INN Partner ont
                    déclaré avoir au moins un membre du personnel incarcéré ou
                    anciennement incarcéré et 12% ont déclaré avoir un membre du
                    conseil incarcéré ou anciennement incarcéré. Bien que
                    beaucoup aient des membres de leur famille incarcérés et /
                    ou des personnes plus touchées en tant que volontaires,, Le
                    RNI espère que les organisations choisiront de les inclure
                    en tant que membres du personnel et membres du conseil bien
                    assistés
                    <br />
                    Pour faciliter ce qui précède, les gouvernements doivent
                    cesser de créer des barrières législatives parce que, selon
                    JustLeadership USA, « les personnes les plus proches du
                    problème» sont «les plus proches de la solution».
                    Professions entières - travail social en Afrique du Sud et
                    au Canada, domaine juridique au Kenya, par exemple, sont
                    essentiellement inaccessibles aux condamnés, qui ont un
                    grand potentiel pour offrir un service extraordinaire aux
                    champs et à leurs communautés. Un litige stratégique à cet
                    égard est nécessaire dans de nombreux pays.
                  </li>
                  <li>
                    <span className="strong">
                      ÉLARGISSEMENT «DIRECTEMENT IMPACTÉ»:{' '}
                    </span>
                    Lorsque nous parlons des personnes touchées par le système
                    de justice pénale, nous devons élargir notre définition:
                    nous devons non seulement considérer ceux qui ont été
                    emprisonnés, mais également leurs familles, ceux qui ont
                    réussi à éviter les peines d'emprisonnement. L’implication
                    de la justice pénale et les quartiers d'où ils viennent -
                    des quartiers surreprésentés dans l'ensemble du système de
                    justice pénale. Les services destinés aux familles des
                    personnes incarcérées et l’attention qccordée aux familles
                    augmentent dans le monde entier, de l’Europe à Singapour, en
                    passant par les États-Unis, mais les services destinés aux
                    quartiers / communautés surreprésentés sont rares. Le Centre
                    de justice communautaire de Red Hook (États-Unis) constitue
                    une exception. Ses services - juridiques, éducatifs et
                    culturels - sont accessibles à tous les membres de la
                    communauté de Red Hook, à Brooklyn, et pas seulement à ceux
                    qui entrent en conflit avec la loi.
                  </li>

                  <li>
                    <span className="strong">PRENDRE LE TRAUMATISME: </span>
                    Le travail de justice ne peut être fait n'importe où sans
                    s'attaquer à l'éléphant dans la pièce: le traumatisme. La
                    population carcérale est enveloppée dedans, à la fois avant
                    la prison (en raison de l'inégalité systémique et du
                    racisme), pendant les années déshumanisantes en prison, et
                    après la libération, confrontée à la réintégration et à la
                    stigmatisation. « L’essence du traumatisme est la
                    déconnexion de nous-mêmes», déclare le médecin Gabor Mate;
                    la prison est le summum de la déconnexion, à bien des
                    égards. Les pratiques informées sur les traumatismes doivent
                    donc régir toutes les interventions. Cela devrait surtout
                    faire l’objet de programmes de réadaptation et de
                    réinsertion ; les compétences professionnelles, l’éducation
                    et les emplois sont vitaux, mais les efforts dans cette
                    direction peuvent rapidement être sabotés lorsque le
                    traumatisme d’une personne n’est ni traité ni guéri.
                  </li>
                  <li>
                    <span className="strong">INNOVATION RADICALE: </span>
                    D'une manière générale, l'espace judiciaire - pilier
                    fondamental de toute société stable - mérite la liberté et,
                    en particulier, le soutien financier pour expérimenter et
                    innover de manière radicale. Si Silicon Valley peut dépenser
                    librement à expérimenter des applications et des
                    technologies jusqu'à trouver ce qui fonctionne, les
                    personnels de la justice ne devraient-ils pas avoir la
                    liberté et le soutien nécessaires pour faire de même ? Nous
                    méritons tous un monde dans lequel l’imagination radicale
                    est valorisée et entretenue, au nom du plus grand bien.
                  </li>
                </ul>
              </div>
            </div>
          </article>
        </div>
      </div>
    </>
  );
};

export default BlueprintFrTemplate;
